<template lang="pug">
canvas.canvas.fixed(ref='canvas')
  img(ref='img', src='~@/assets/star.png')
</template>

<script>
window.__DEBUG__ = false;
import createREGL from 'regl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import mat4 from 'gl-mat4';

import vert from '@/shaders/particle-star.vert';
import frag from '@/shaders/particle-star.frag';

export default {
  name: 'Stars',
  setup() {
    const canvas = ref(null);
    const img = ref(null);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });

      const WHITE = regl.texture(Array(4).fill([1, 1, 1]));

      let texture;
      img.value.addEventListener('load', () => {
        texture = regl.texture(img.value);
      });

      const draw = regl({
        vert,
        frag,
        context: {
          projection: ({ viewportWidth, viewportHeight }) =>
            mat4.perspective([], (Math.PI * 2) / 3, viewportWidth / viewportHeight, 0.01, 1000),
          eye: ({ tick }) => {
            const t = 0.001 * tick;
            // return [4, 0, 0];
            return [0, 0, 10];
            // return [3, 200 * Math.cos(t), 200 * Math.sin(t)];
          },
        },
        uniforms: {
          projection: ({ projection }) => projection,
          model: mat4.identity([]),
          view: mat4.lookAt([], [0, 0, 10], [0, 0, 0], [0, 1, 0]),
          time: regl.prop('time'),
          texture: () => texture || WHITE,
        },
        attributes: {
          position: [[0, 0, 0]],
        },
        count: 1,
        primitive: 'points',
        depth: { enable: false },
        blend: {
          enable: true,
          func: {
            srcRGB: 'src alpha',
            srcAlpha: 'src alpha',
            dstRGB: 'one minus src alpha',
            dstAlpha: 'one minus src alpha',
          },
        },
      });

      let time = Date.now() / 1000;
      let updatedAt = Date.now();
      const update = () => {
        const now = Date.now();
        time += (now - updatedAt) / 1000;
        updatedAt = now;
        draw({ time });
      };
      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  cursor pointer
  background #000

  img
    display none
</style>
